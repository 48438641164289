import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/en"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"

const CuinaPage = (props) => {
	const heroText = "Food"
	const textSectionText = "Seasonal and local product. We combine the traditional cuisine of our grandparents with the avant-garde gastronomic nuances we have today. We love our land and we feel like ours the peas from Llavaneres, the veal from Girona, the prawns from Vilanova or the artichokes from El Prat. The Gall del Penedès, the Sant Pau d'Ordal peach and the Montserrat tomato. We know ourselves privileged to have these treasures at hand and our cuisine is the most sincere expression of our passion. We hope you like it."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Food"
	    	lang="en"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="cuina" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allCuinaImagesEnYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
	          images={data.allCuinaImagesEnYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default CuinaPage
